import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import hero from "../images/praying-boy.jpg"
import Homeslider from "../components/homeslider"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Homeslider />
    <div className="home-page-welcome">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 order-2 order-lg-1">
            <div className="welcome-content">
              <header className="entry-header">
                <h2 className="entry-title" style={{ color: "#002060" }}>
                  Indian missionaries reaching Indian people
                </h2>
              </header>

              <div className="entry-content mt-5">
                <p>
                  100% of missionary salaries come from the Indian church. It’s
                  a sustainable way of working. Overseas donations go towards
                  supporting projects and capital expenses. You can help
                  multiply the effectiveness of missionaries in India by
                  supporting a project.
                </p>
              </div>

              <div className="entry-footer mt-5">
                <Link to="/projects" className="btn gradient-bg mr-2">
                  Support a Project
                </Link>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6 mt-4 order-1 order-lg-2">
            <img src={hero} alt="Praying" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
